import zh from '@/locales/zh.json'
import en from '@/locales/en.json'

const Lang = {
  zh,
  en,
}
/**
 * @function 根据key获取语言包的语句
 * @param {Number} lanuage 语言，0为中文，1为英文
 * @param {*} langKey 语言包的key
 */
function getMessage(lanuage, langKey) {
  return Lang[lanuage === 1 ? `en` : `zh`][langKey]
}

class searchFrom {
  constructor(type, lanuage = 0) {
    if (type === 'form') {
      this.loginName = ''
      this.password = ''
    } else if (type === 'rule') {
      this.loginName = [
        {
          required: true,
          message: getMessage(lanuage, 'ple_loginName'),
          trigger: 'blur',
        },
      ]
      this.password = [
        {
          required: true,
          message: getMessage(lanuage, 'ple_password'),
          trigger: 'blur',
        },
      ]
    }
  }
}
export default searchFrom
