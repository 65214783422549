<template>
  <el-form ref="form" :model="form" :rules="formRule" label-width="80px">
    <el-form-item label="用户名" prop="loginName">
      <el-input clearable v-model="form.loginName"></el-input>
    </el-form-item>
    <el-form-item label="密码" prop="password">
      <el-input clearable v-model="form.password"></el-input>
    </el-form-item>
    <el-button @click="loginSubmit">登录</el-button>
    <el-button @click="loginOut">注销</el-button>
  </el-form>
</template>

<script>
import md5 from 'js-md5'
import CryptoJS from 'crypto-js'
import { mapState, mapActions } from 'vuex'
import apis from '@/apis'
import UserLoginClass from './userLoginClass'

export default {
  data() {
    return {
      form: new UserLoginClass('form'),
      formRule: new UserLoginClass('rule'),
    }
  },
  computed: {
    ...mapState(['timestampDiff']),
  },
  methods: {
    ...mapActions({
      Login: 'Login',
    }),
    /**
     * @function 提交登录
     * @description 一般在滑块验证码通过后调用，因为滑块验证码还没好，先跳过验证码
     */
    loginSubmit() {
      const formData = { ...this.form }
      // formData.password = md5(this.user.loginName + md5(respone.ticket + (this.md5Password === this.user.password ? this.user.password : md5(this.user.password))))
      const timestamp = (new Date()).getTime() + this.timestampDiff
      formData.timestamp = timestamp
      const salt = 'One@-!Sports123G'
      const newPass = md5(timestamp + salt)
      const aes = newPass.substring(0, 16)
      const iv = newPass.substring(16)
      formData.password = this.encrypt(formData.password, aes, iv)
      this.$http.post(apis.Auth_Login, formData).then((res) => {
        if (res.data.code === 0) {
          const data = { ...res.data.data }
          data.token = res.headers['x-xsrf-token']
          this.Login(data)
          console.log(res)
        }
      })
    },
    /**
     * @function 对登录的时候密码进行加密
     * @param {String} word 要被加密的文本
     * @param {String} keyStr 密钥
     * @param {String} ivStr  偏移量
     * @returns {String} 返回加密后的文本
     */
    encrypt(word, keyStr, ivStr) {
      const key = CryptoJS.enc.Utf8.parse(keyStr)
      const iv = CryptoJS.enc.Utf8.parse(ivStr)
      const srcs = CryptoJS.enc.Utf8.parse(word)
      const encrypted = CryptoJS.AES.encrypt(srcs, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
      return encrypted.toString()
    },
    /**
     * @function 退出
     */
    loginOut() {
      this.$http.post(apis.Auth_Logout).then((res) => {
        if (res.data.code === 0) {
          // 退出成功
        }
      })
    },
  },
}
</script>

<style>

</style>
